<template>
  <div>
    <div>
      <div class="demo-inline-spacing mb-2">
        <b-button v-b-modal.permission-modal variant="primary">
          Nouvelle demande
        </b-button>
      </div>

      <b-modal ref="my-modal" show="resetModal" id="permission-modal" centered modal-class="modal-primary"
        title="Demande de permission." hide-footer>
        <validation-observer ref="permissionForm">
          <b-form>
            <b-form-group>
              <ky-input v-model="permission.object" :placeholder="'Objet de permission'" :rules="'required'"
                :type="'text'" />
            </b-form-group>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group>
                    <ky-input v-model="start_date" :placeholder="'Date de départ'" :rules="'required'" :type="'date'" />
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <ky-input v-model="start_time" :placeholder="'Heure de depart'" :rules="'required'"
                    :type="'time'" />
                  </b-form-group>
                </div>
              </div>
              
              <div class="row">
                <div class="col-md-6">
                  <b-form-group>
                    <ky-input v-model="end_date" :placeholder="'Date de d\'arrivée'" :rules="'required'" :type="'date'" />
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <ky-input v-model="end_time" :placeholder="'Heure d\'arrivée'" :rules="'required'"
                      :type="'time'" />
                  </b-form-group>
                </div>
              </div>
            <b-form-group>
              <ky-input :isTextarea="true" placeholder="Message" name="description" v-model="permission.description"
                :rules="'required'" />
            </b-form-group>
            <b-form-group>

              <b-button :disabled="loading" @click.prevent="requestPermissions">
                <b-spinner v-if="loading" small type="grow"></b-spinner> Ajouter
              </b-button>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
    <b-card style="padding: 0rem">
      <b-card-body style="padding: 0rem">
        <div class="">
          <div>
            <div class="title">
              <h4>Demande de permission</h4>
              <h4 class="text-muted">
                {{ userInfo.lastname + " " + userInfo.firstname }}
              </h4>
            </div>

            <div class="row ml-1 mt-4 mb-2">
              <p>Légende :</p>
              <div class="">
                <span class="fs-6 text-center alert-success"
                  style="font-size: 90%; padding: 1vh; border-radius: 10px">Accordée</span>
              </div>
              <div class="ml-1 xl-top-2">
                <span class="fs-6 text-center alert-danger"
                  style="font-size: 90%; padding: 1vh; border-radius: 10px">Rejetée</span>
              </div>
              <div class="ml-1">
                <span class="fs-6 text-center alert-secondary"
                  style="font-size: 90%; padding: 1vh; border-radius: 10px">En attente de reponse</span>
              </div>
              <b-form-group label="" label-cols-sm="0" label-align-sm="left" label-size="" label-for="filterInput"
                class="mb-2 ml-auto mr-1">
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Rechercher..." />
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-card-body>

      <b-table no-border-collapse hover responsive class="position-relative" :per-page="perPage"
        :current-page="currentPage" :items="permissions" :fields="permissionItems" :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn"
        @filtered="onFiltered" :tbody-tr-class="rowClass">
        <template #cell(actions)="row">
          <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
            Voir details
          </b-button>
        </template>
      </b-table>

      <!-- Info modal -->
      <b-modal :header-bg-variant="headerBgVariant" :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant" :body-text-variant="bodyTextVariant" :footer-bg-variant="footerBgVariant"
        :id="infoModal.id" :title="infoModal.title" ok-only header-close-variant="danger" @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Affichage par page" label-cols="8" label-align="left" label-size="sm"
          label-for="sortBySelect" class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
            prev-class="prev-item" next-class="next-item" class="mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import kyInput from "@/@core/layouts/components/ky-input.vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { BProgress } from "bootstrap-vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BCard,
    BProgress,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    ToastificationContent,
    kyInput,
  },
  data() {
    return {
      loading: false,
      object: "",
      description: "",
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      variants: ["light", "dark"],
      headerBgVariant: "",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "light",

      permissionItems: [
        { key: "start_date", label: "Départ" },
        { key: "end_date", label: "Arrivée" },
        { key: "object", label: "Objet" },
        { key: "actions", label: "" },
      ],
      /* eslint-disable global-require */
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    ...mapState("member", ["permissions", "permission"]),
    ...mapState("auth", ["userInfo"]),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.permissions.length;

    this.fetchPermissions(this.userInfo.id);
  },
  methods: {
    async requestPermissions() {
      const valid = await this.$refs.permissionForm.validate();
      if (!valid) return;
      this.loading = true;
      this.permission.start_date = `${this.start_date} ${this.start_time}`;
      this.permission.end_date = `${this.end_date} ${this.end_time}`;

      this.requestPermission(this.$formData(this.permission))
        .then((r) => {
          this.loading = false;
          this.permission.object = "";
          this.permission.description = "";
          this.permission.start_time = "";
          this.permission.end_time = "";
          this.permission.date = "";
          this.resetModal = false;
          this.$refs["my-modal"].hide();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Demande de permission envoyée avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });

        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error.message;
          this.popover_disabled = false;
          this.$refs.form_popover.$emit("open");
        });
    },

    onFiltered(permissions) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = permissions.length;
      this.currentPage = 1;
    },
    rowClass(item, type) {
      const colorAccorde = "table-success text-success";
      const colorRefuse = "table-danger text-danger";
      const colorAttente = "table-primary text-primary";
      if (!item || type !== "row") {
        return;
      }

      // eslint-disable-next-line consistent-retu
      if (item.allowed == true) {
        return colorAccorde;
      }
      if (item.allowed == false) {
        return colorRefuse;
      }
      if (item.allowed == null) {
        return colorAttente;
      }
    },

    info(item, index, button) {
      this.infoModal.title = `Demande de permission`;
      this.infoModal.content =
        "Objet : " +
        item.object +
        "\n------\nDescription : " +
        item.description;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },

    ...mapActions("member", ["requestPermission", "fetchPermissions"]),
    ...mapActions("enterprise", ["fetchServices"]),
  },
};
</script>